import React,{useEffect,useState} from 'react'
import styled from 'styled-components'
import {AnimatePresence, motion} from "framer-motion"
import { useLocation } from 'react-router';
// import Footer from './Footer';
import { ReactDOM } from 'react';
// import Popups from './Cards/Popups';
// import { PrimaryThemeColor ,PrimaryFont } from './Constants';


const MainCont = styled(motion.div)`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin-left: auto;
margin-right: auto;
margin-top: 50px;
margin-bottom: 50px;
max-width: 1200px;
@media (max-width: 1100px) {
  width:95%;
}
@media (max-width: 768px) {
    width: 90%;
    /* max-width: 450px; */
}
`;

const SubCont = styled.div`
width: 80%;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: row;
margin-top: 50px;
margin-bottom: 50px;
@media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
margin-bottom: 0px;
}
`;



const BlogTitle = styled.h1`
font-family: 'Poppins', sans-serif;
font-weight: 700;
font-size: 50px;
line-height: 58px;
width: 80%;
margin-left: auto;
margin-right: auto;
color: #090a0b;
text-align: center;
@media (max-width: 1100px) {
  width:95%;
}
@media (max-width: 768px) {
    font-size: 20px;
    line-height: 20px;
    width: 100%;
}
`;

const BlogDesc = styled.h1`
 font-family: 'Open Sans', sans-serif;
font-weight: 400;
font-size: 22px;
line-height: 35px;
width: 80%;
margin-top: 30px;
margin-left: auto;
margin-right: auto;
color: #3c484e;
`;

const BlogDes = styled.h1`
 font-family: 'Open Sans', sans-serif;
font-weight: 400;
font-size: 30px;
line-height: 35px;
color: #3c484e;
@media (max-width: 768px) {
    font-size: 10px;
line-height: 15px;  
}
`;
const WrittenBy = styled.h1`
 font-family: 'Open Sans', sans-serif;
font-weight: 400;
font-size: 30px;
line-height: 35px;
color: #3c484e;
width: 80%;
text-align: right;
margin-top: 50px;
@media (max-width: 768px) {
    font-size: 10px;
line-height: 15px;  
margin-top: 0px;
}
`;

const ImageBlock = styled.img`
width: 80%;
height: 50vh;
border-radius: 15px;
margin-left: auto;
margin-right: auto;
object-fit: cover;
margin-top: 50px;


@media (max-width: 1100px) {
  width:80%;
}
@media (max-width: 768px) {
    margin-top: 20px;
  height: 180px;
width:100%;
}
`;

const PopupMainCont = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 120vh;
width: 100%;
position: absolute;
background-color: #000000d1;
z-index:100;
top: 0;
left:0;
position: fixed;
backdrop-filter: blur(4px);
`;

// const Frame = styled.iframe`
// width: 100%;
// height: 100vh;
// /* overflow: visible; */
// `;


// const Iframe = () => {


//     const [frameHeight , setFrameHeight] = useState("0px")



//     return (
//         <iframe srcdoc={d.d.content}
//         id="myFrame"
//         width="100%" 
//         height={frameHeight}
//         marginwidth="0"  marginheight="0" align="top"  frameborder="0" hspace="0" vspace="0"  
//         // scrolling="no"
//         // styles={{overflow:"auto",height:"100%"}} 
//         onLoad={() => {
//             const obj = ReactDOM.findDOMNode(this);
           
//             setFrameHeight(obj.contentWindow.document.body.scrollHeight + 'px')
       
//         }} 
//         ></iframe>
//     )
// }

function Blog(props) {
    const location = useLocation()
    
    const [frameHeight , setFrameHeight] = useState()
    const [popUp , setPopUp] = useState(false);

        

    const d = location.state;
    

    console.log("**",d)

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
        }

 const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


  useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])


    

    useEffect(() => {
        window.scrollTo(0, 0);
        var frame = document.getElementById('myFrame');
        console.log("height" , frame.contentWindow.document.body.scrollHeight + "px")
        
        setTimeout(() => {
            setFrameHeight(frame.contentWindow.document.body.scrollHeight + 50 + "px")
        },100)
    
        frame.onload = function () {
            var body = frame.contentWindow.document.querySelector('body');
        
            body.style.color = '#3c484e';
            body.style.fontSize =windowDimensions.width < 768 ? '20px' : '21px';
            body.style.lineHeight = windowDimensions.width < 768 ? '20px' : '31px';
            body.style.fontFamily= "Open Sans, sans-serif";
           

        };

    //     document.title = d.d.title
    // document.getElementById("icon").href = d.d.image_url
    // return document.getElementById("icon").href = ""
    },[])

    const onclick = () => {
        setPopUp(!popUp)
      }


    return (
        <>

        <MainCont
        initial={{opacity:0}}
        animate={{opacity:1}}
        >
            <BlogTitle>{d.d.title}</BlogTitle>
            <ImageBlock src={d.d.image_url} />
            <SubCont>
            <BlogDes>
                {d.d.added_on}
            </BlogDes>
            <BlogDes>
                {d.d.read_time}
            </BlogDes>
            </SubCont>
            <iframe srcdoc={d.d.content}
        id="myFrame"
        width={windowDimensions.width < 768 ? '100%' : '80%'}
        height={frameHeight}
        frameBorder="0"
        scrolling="no"
        style={{marginTop:windowDimensions.width < 768 ? '0px' : "20px"}} 

  
        ></iframe>

        <WrittenBy >
            Written By : {d.d.written_by}
        </WrittenBy>
      
            {/* <BlogDesc>
            Before we get to the story, a recap of some of the big ideas presented in the article we published yesterday.
            </BlogDesc>
            <BlogDesc>
            Economists and policymakers often spend decades trying to study the causal relationship between variables — for instance, the impact of education on lifetime earnings, or the effect of minimum wage on unemployment. The only problem — Such analysis can be fraught with inconsistencies if you don’t do it right.
            </BlogDesc>
            <BlogDesc>
            In other disciplines, researchers often adopt randomized controlled trials in an attempt to remove bias and inconsistency. As we outlined yesterday — In the field of medicine, you could study the effects of a pill by taking one set of participants and administering the dose while offering another group a placebo — saltwater for instance. If at the end of the experiment, the group that received the pill showed significant improvements compared to the group that received the saline solution, you could safely determine that the pill did its job. Unfortunately designing such experiments in real life can be particularly cumbersome. You can’t decide who gets to have an education and who doesn’t.
            </BlogDesc>
            <BlogDesc>
           Laboris sint voluptate magna enim in. Nisi excepteur aliqua culpa non aliqua. Consectetur occaecat consequat magna laboris irure amet amet non fugiat commodo magna qui. Esse esse minim voluptate reprehenderit ea amet. Quis mollit dolore velit cillum Lorem cupidatat amet mollit officia non sit ex.
            </BlogDesc>
            <BlogDesc>
            In other disciplines, researchers often adopt randomized controlled trials in an attempt to remove bias and inconsistency. As we outlined yesterday — In the field of medicine, you could study the effects of a pill by taking one set of participants and administering the dose while offering another group a placebo — saltwater for instance. If at the end of the experiment, the group that received the pill showed significant improvements compared to the group that received the saline solution, you could safely determine that the pill did its job. Unfortunately designing such experiments in real life can be particularly cumbersome. You can’t decide who gets to have an education and who doesn’t.
            </BlogDesc>
            <BlogDesc>
            Economists and policymakers often spend decades trying to study the causal relationship between variables — for instance, the impact of education on lifetime earnings, or the effect of minimum wage on unemployment. The only problem — Such analysis can be fraught with inconsistencies if you don’t do it right.
            </BlogDesc> */}
        </MainCont>

        </>
    )
        }


export default Blog
