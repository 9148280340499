import React, {Component} from "react"


  class Spinner extends Component{

    render(){

      return (
         <div class="loader">Loading...</div>
      )
    }
  }


 export default Spinner;
