import React ,{useState,useEffect}from 'react'
import styled from 'styled-components';
import { motion} from 'framer-motion';

import { Link } from 'react-router-dom';
import axios from 'axios';



const MainCont = styled.div`
width:100%;
margin-bottom: 200px;
margin-left: auto;
margin-right: auto;
max-width: 1200px;
@media (max-width: 1100px) {
  width:95%;
}
@media (max-width: 768px) {
    width: 90%;
    margin-top: 30px;
margin-bottom: 100px;
max-width: 450px;
}
`;

const NewsTitle = styled(motion.h1)`
font-family: 'Poppins', sans-serif;
font-weight: 800;
font-size: 50px;
line-height: 91px;
margin-bottom: 20px;
margin-top: 100px;
@media (max-width: 768px) {
  font-size: 36px;
line-height: 40px;

    margin-top: 40px;
margin-bottom: 40px;
}
`;

const BlogsTitle = styled.h1`
font-family: 'Poppins', sans-serif;
font-weight: 800;
font-size: 46px;
line-height: 46px;
margin-bottom: 50px;
margin-top: 50px;
@media (max-width: 768px) {
  line-height: 60px;
}
`;

const PaginationBlock = styled(motion.div)`
display: flex;
flex-direction: row;
justify-content: end;
gap: 20px;
margin-top: 50px;
-webkit-box-pack: end;
-webkit-justify-content: flex-end;
    -ms-flex-pack: end;
        justify-content: flex-end;
@media (max-width: 768px) {
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}
`;

const PageBlock = styled(motion.div)`
width: 54px;
height:62px;
border-radius: 8px;
border: 1px solid #0863FF;
display: flex;
cursor: pointer;

justify-content: center;
align-items: center;
background-color: ${props => props.color};
@media (max-width: 768px) {
  width: 30px;
height:40px;
}
`;


const BlockNumber = styled.h1`
font-family: 'Montserrat', sans-serif;
font-weight: 500;
font-size: 32px;
color:${props => props.color};
line-height: 43px;
@media (max-width: 768px) {
  font-size: 14px;
}
`;

const ContentPagination = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
gap: 20px;
margin-top: 100px;
margin-left: auto;
margin-right: auto;
@media (max-width: 768px) {
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 50px;
}
`;

const ContentBlock = styled.div`
display: flex;
flex-direction: column;
cursor: pointer;

`;

const PopupMainCont = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 120vh;
width: 100%;
position: absolute;
background-color: #000000d1;
z-index:100;
top: 0;
left:0;
position: fixed;
backdrop-filter: blur(4px);
`;

const NewsBlock = styled(motion.div)`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
gap: 20px;
@media (max-width: 768px) {

grid-template-columns: 1fr ;

}
`;

const NewsBlockCont = styled(motion.div)`
height: 155px;
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
background-color: #F4F4F4;
border-radius: 32px;
padding: 5px;
@media (max-width: 1366px) {
  height: 125px;;
} 
@media (max-width: 768px) {
  height: 117px;


}
`;

const NewsBlockImgCont = styled.div`
width: 40%;
display: flex;
justify-content: center;
align-items: center;

border-radius: 15px;
`;

const NewsImg = styled.img`
width: 80%;
max-height: 130px;
border-radius: 32px;
object-fit: cover;
@media (max-width: 1366px) {
  max-height: 90px;
} 
`;

const NewsBlockContentCont = styled.div`
width: 60%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

const NewsBlockContentSub = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 95%;
margin-top: 20px;
`;

const NewsContTitle = styled.h1`
font-family: 'Poppins', sans-serif;
font-weight: 500;
font-size: 20px;
/* height: 60px;
overflow: hidden; */
@media (max-width: 1366px) {
    font-size: 14px;
} 
`;

const NewsContDate = styled.h1`
font-family: 'Poppins', sans-serif;
font-weight: 500;
font-size: 14px;
@media (max-width: 1366px) {
    font-size: 12px;
} 
`;
const NewsReadMore = styled.h1`
font-family: 'Poppins', sans-serif;
font-weight: 700;
font-size: 14px;
color:#0863FF;
margin-right: 10px;
@media (max-width: 1366px) {
    font-size: 12px;
} 
`;
const AllBlogsCont = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
width: 100%;
gap:20px;
@media (max-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr;
    gap:10px;
}
`;

const BlogBlock = styled(motion.div)`
/* min-width: 381px; */
position: relative;
width: 100%;
border-radius: 15px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
margin-bottom: 10px;
background-color: "#fff";
overflow: hidden;
margin-left: 20px;
height: 450px;

@media (max-width: 1366px) {
  height: 380px;
} 
@media (max-width: 768px) {
    min-width: 90%;
    margin-left: 5px;
    margin-right: 5px;
    height: 300px;
    /* height: 461px; */
}
`;

const BlogImg = styled.img`
width: 100%;
height: 251px;
object-fit: cover;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
margin-bottom: 30px;
@media (max-width: 1366px) {
    height: 191px;
} 
@media (max-width: 768px) {
    height: 170px;
    margin-bottom: 10px;
}
`;

const BlogPr = styled.h1`
 font-family: 'Poppins', sans-serif;
font-weight: 400;
font-size: 12px;
margin-left: 20px;
color:#FF5C5C;
@media (max-width: 1366px) {
    font-size: 10px;
} 
@media (max-width: 768px) {

}

`;

const BlogText = styled.h1`
 font-family: 'Poppins', sans-serif;
font-weight: 700;
font-size: 25px;
margin-left: 20px;
/* margin-top: 40px; */
width: 90%;
@media (max-width: 1366px) {
    font-size: 21px;
} 
@media (max-width: 768px) {
    margin-top: 10px;
    font-size: 15px;
}
`;

const BlogDate = styled.h1`
position: absolute;
 font-family: 'Poppins', sans-serif;
font-weight: 400;
font-size: 12px;
color:#969696;
/* text-align: right; */
margin-left: 20px;
bottom: 20px;
/* margin-bottom: 30px;
margin-top: 30px; */
`;


function Blogs(props) {



  const [popUp , setPopUp] = useState(false);
  const [blogsData ,setBlogsData] = useState([])
  const [news ,setBlogsNews] = useState([])
  const [page , setPage] = useState(1)




useEffect(() => {

  window.scrollTo(0, 0);
    const filterBlog = blogsData.slice((page-1) * 6 , page*6)
    setBlogsData(filterBlog)

    // const filterNews = newsContent.slice((page-1) * 12 , page*12)
    // setBlogsNews(filterNews)


    var qs = require('qs');







    var data = qs.stringify({
      'token': 'f91c74e65cba358f355c041395e4376d',
      'entity': 'news',
    });
    var config = {
      method: 'post',
      url: 'https://adminn.indiaport.in/api/getData?token=f91c74e65cba358f355c041395e4376d&entity=news',
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Cookie': 'ci_session=rnl2r9khjmck303p5ns0noeohh973igh'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      const data = response.data.data;
      const filterNews = data.slice((page-1) * 12 , page*12)
      setBlogsNews(filterNews)
    })
    .catch(function (error) {
      console.log(error);
    });
  

    var dataa = qs.stringify({
      'token': 'f91c74e65cba358f355c041395e4376d',
      'entity': 'blogs',
    });
    var configg = {
      method: 'post',
      url: 'https://adminn.indiaport.in/api/getData?token=f91c74e65cba358f355c041395e4376d&entity=news',
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Cookie': 'ci_session=rnl2r9khjmck303p5ns0noeohh973igh'
      },
      data : dataa
    };
    
    axios(configg)
    .then(function (response) {
      console.log("DATA =>",JSON.stringify(response.data));
      const data = response.data.data;
      const filterBlog = data.slice((page-1) * 12 , page*12)
      setBlogsData(filterBlog)
    })
    .catch(function (error) {
      console.log(error);
    });

 console.log(news)

},[page])


  const onclick = () => {
    setPopUp(!popUp)
  }
  
    return (
        <div>
             <MainCont>
              <NewsTitle
              initial={{opacity:0}}
              animate={{opacity:1}}
              >News</NewsTitle>
              <NewsBlock>
              {
              news.map((d,i) => {

                var title = ""

                const filter = () => {

                  if(d.title.length > 30){
                    console.log("/*/*/*/*/*/*/*/*/*/*/",typeof(d.title))
                    title = d.title.slice(0,30) + "...."
                    // setTitleNews(d.title.slice(0,40) + "....")
                    return title;
                  }else{
                    // setTitleNews(d.title)
                    title = d.title;
                    return title;
                  }
  
                };
              
                 
                filter();

                  return (
                    <Link
                    key={i}
                    style={{
                      textDecoration:"none",
                      color: "#000000",
                    }}
                    to={{
                      pathname:"/blogs/"+d.news_id,
                      state:{
                     d
                      }
                    }}
                    >
                    <NewsBlockCont
                    initial={{opacity:0}}
                    animate={{opacity:1}}
                    whileHover={{scale:1.05}}
           >
                      <NewsBlockImgCont >
                        <NewsImg src={d.image_url} />
                      </NewsBlockImgCont>
                      <NewsBlockContentCont>
                        <NewsContTitle>
                          {title}
                        </NewsContTitle>
                        <NewsBlockContentSub>
                          <NewsContDate>{d.added_on}</NewsContDate>
                          <NewsReadMore>Read More</NewsReadMore>
                        </NewsBlockContentSub>
                      </NewsBlockContentCont>
                    </NewsBlockCont>
                    </Link>
                  )
                })
              }
              </NewsBlock>
              <NewsTitle
                  initial={{opacity:0}}
                  animate={{opacity:[0,0,0,0,1]}}
              >Blogs</NewsTitle>
              <AllBlogsCont

                  >
                      {
               blogsData.map((d,i)=>{

                if(d.title){
                  console.log("/*/*/*/*/*/*/*/*/*/*/",d.title.length)
                     var title = ""

                const filter = () => {

                  if(d.title.length > 50){
                    console.log("/*/*/*/*/*/*/*/*/*/*/",d.title.length)
                    title = d.title.slice(0,45) + "...."
                    // setTitleNews(d.title.slice(0,40) + "....")
                    return title;
                  }else{
                    // setTitleNews(d.title)
                    title = d.title;
                    return title;
                  }
  
                };
              
                 
                filter();
                }

               

             
         

                      return(
                        <Link
                        key={i}
                        style={{
                          textDecoration:"none",
                          color: "#000000",
                        }}
                        to={{
                          pathname:"/news/"+d.blog_id,
                          state:{
                         d
                          }
                        }}
                        >
                          <BlogBlock
                          whileHover={{scale:1.05}}
                          initial={{opacity:0}}
                          animate={{opacity:[0,0,0,0,1]}}
                          className="items"
                    
                          >
                              <BlogImg src={d.image_url} />
                              <BlogText>{title}</BlogText>
                              <BlogDate>{d.added_on}</BlogDate>
                          </BlogBlock>
                          </Link>
                      )
                  })
                  }

                  </AllBlogsCont>
                  <PaginationBlock>
              {
                [...Array(10)].map((d,i) => {
                  return (
                    <PageBlock
                    key={i}
                    whileTap={{scale:0.9}}
                    color={ page == i +1 ? "#0863FF" : "white"}
                    onClick={() => {
                      setPage(i+1)
                    }}
                    >
                      <BlockNumber
                       color={ page == i +1 ?"white" : "#0863FF"}
                      >
                      {i+1}
                      </BlockNumber>
                    </PageBlock>
                  )
                })
              }
            </PaginationBlock>
             </MainCont>
      
        </div>
    )
}

export default Blogs
